<template>
    <v-dialog
      max-width="500"
      v-model="open"
    >
        <v-card>
            <v-card-title style="overflow: hidden;white-space: nowrap;">{{ headlineName }}</v-card-title>
            <v-card-text>
            <v-form v-model="valid">
                <v-text-field
                    :rules="validationRules"
                    :label="labelName === '' ? $t('general.name') : labelName"
                    outlined
                    maxlength="255"
                    v-model="newItemName"
                    v-on:keyup.enter="isDisabled ? false : handleAction()"
                    v-on:keydown.enter.prevent
                    ref="newItemForm"
                ></v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-btn text @click="handleCancel()" class="no-transform">
                {{ cancelName }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green" raised :disabled="isDisabled || !valid" @click="handleAction()" class="no-transform">
                <span class="white-text">{{ actionName }}</span>
            </v-btn>
            </v-card-actions>
        </v-card>
  </v-dialog>
</template>
<script>
export default {
    name: "NamingDialog",
    data: function() {
        return {
            newItemName: this.baseValue === undefined ? "" : this.baseValue,
            errorMessage: "",
            valid: true
        }
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        baseValue: {
            type: String,
            default: "",
        },

        headlineName: {
            type: String,
            required: true,
        },
        labelName: {
            type: String,
            default: '',
        },

        validationRules: {
            type: Array[Function],
            default: () => [() => (true)]
        },

        cancelName: {
            type: String,
            required: true,
        },
        cancelFunction: {
            type: Function,
            required: false,
        },
        actionName: {
            type: String,
            required: true,
        },
        actionFunction: {
            type: Function,
            required: false,
        }
    },
    computed: {
        open: {
            // getter
            get() {
                return this.value
            },
            // setter
            set(newValue) {
                this.$emit('input', newValue)
            }
        },
        isDisabled: function () {
            return this.newItemName === this.baseValue || this.newItemName === null || this.newItemName.length == 0;
        }
    },
    methods: {
        closeDialog() {
            this.open = false
        },
        handleCancel() {
            if(this.cancelFunction instanceof Function)
                this.cancelFunction(this.newItemName);

            this.closeDialog();
        },
        handleAction() {
            if(this.actionFunction instanceof Function)
                this.actionFunction(this.newItemName);

            this.closeDialog();
        },
    },
    mounted() {
        // Selectionner le text area
        setTimeout(() => {
            this.$refs.newItemForm.focus();
        }, 200);
    }
}
</script>
<style scoped>
</style>
