import axios from 'axios';
import authHeader from './auth-header';

import constants from '../constants';
import { parseError, buildParamsUrl } from '@/utils/utils'
var API_URL = constants.API_URL + 'file/';
var PROJECT_API_URL = constants.API_URL + 'project/';
var DOCUMENT_REQUEST_API_URL = constants.API_URL + 'documentRequest/';

class FileService {

    async addNewFile(file, relatableType, relatableId, additionalData) {
      let sizeMb = (file.size / 1024 / 1024).toFixed(4);
      if (sizeMb > 25) return { success: false, data: 'reportInfo.file_too_big' };

      var data = new FormData();
      data.append("file", file);
      data.append("relatableType", relatableType);
      data.append("relatableId", relatableId);
      if (additionalData) {
        for (let k in additionalData) data.append(k, additionalData[k]);
      }
      try {
        let headers = authHeader();
        headers['Content-Type'] = 'multipart/form-data';
        let response = await axios.post(API_URL + 'add', data, { headers: headers });
        return { success: true, data: response.data };
      }
      catch (err) {
        return { success: false, data: parseError(err) };
      }
    }

    async addProjectFile(id, relatableType, relatableId, category) {
      try {
        let data = {
          relatableType: relatableType,
          relatableId: relatableId,
          category: category
        }
        let response = await axios.post(API_URL + `${id}/addProjectFile`, data, { headers: authHeader() });
        return { success: true, data: response.data };
      } catch (error) {
        return { success: false, data: parseError(error) };
      }
    }

    async addNewProjectFile(filelist, projectId) {
      try {
        let headers = authHeader();
        headers['Content-Type'] = 'multipart/form-data';
        let response = await axios.post(PROJECT_API_URL + projectId + "/file", filelist, { headers: headers });
        return { success: true, data: response.data };
      }
      catch (err) {
        return { success: false, data: parseError(err) };
      }
    }

    async addNewDocumentRequestFile(filelist, documentRequestId) {
      try {
        let headers = authHeader();
        headers['Content-Type'] = 'multipart/form-data';
        let response = await axios.post(DOCUMENT_REQUEST_API_URL + documentRequestId + "/file", filelist, { headers: headers });
        return { success: true, data: response.data };
      }
      catch (err) {
        return { success: false, data: parseError(err) };
      }
    }

    async deleteFile(file, params) {
      // console.log("Site Report Service / update ", file)
      try {
        let headers = authHeader();
        let url = API_URL + file.id + buildParamsUrl(params);
        let response = await axios.delete(url, { headers: headers });
        return { success: true, data: response.data };
      }
      catch (err) {
        return { success: false, data: parseError(err) };
      }
    }

    async rotateImage(image) {
      try {
        let response = await axios.get(API_URL + `${image.id}/${image.hash}/rotate`, { headers: authHeader() });
        return { success: true, data: response.data };
      } catch (error) {
        return { success: false, data: parseError(error) };
      }
    }

    async renameFile(file) {
      try {
        let response = await axios.post(API_URL + `${file.id}/rename`, {newName: file.filename}, { headers: authHeader() });
        return { success: true, data: response.data };
      } catch (error) {
        return { success: false, data: parseError(error) };
      }
    }

}

export default new FileService();
